<template>
  <div class="page">
    <section id="banner" class="align-middle">
      <banner-image-overlay></banner-image-overlay>
      <h2 class="title">Cookies Policy</h2>
    </section>
    <div class="container 75%">
      <section class="row 200%">
        <p>At MySpanishnow ("we", "us" or "our") use cookies to improve your experience
          in our website, as well as to improve our content and services.
          By continuing to use this website, we assume that you consent
          this Cookies Policy.</p>
        <p>Cookies are files saved on your device (e.g. phone, tablet or
          computer) when you visit a website.</p>
        <p>As an user, you may disable our Cookies by properly configurating your
          browser (see section <a href="#disable-cookies">Disable Cookies</a>).</p>
      </section>
      <section class="row 200%">
        <h3>Cookies that we use</h3>
        <p>In our website we use session Cookies as well as third party Cookies.
          These allow us to:</p>
        <ul>
          <li>Make our website working properly</li>
          <li>Improve your experience, by avoiding you the headache of login in,
            everytime you open/re-open a new page</li>
          <li>Make our website more secure and faster</li>
          <li>Provide and sell you products/services</li>
          <li>Allow you to watch videos</li>
          <li>Improve our content thanks to heatmaps and statistics</li>
        </ul>
        <p>However, we would <b>NEVER</b> get:</p>
        <ul>
          <li>Your personal information (without your explicit consent)</li>
          <li>Sensible information (without your explicit consent)</li>
        </ul>
      </section>
      <section class="row 200%">
        <h4>Third party Cookies</h4>
        <h5>Stripe</h5>
        <p>One of the third party services that we use is
          <a href="https://stripe.com/" target="_blank">Stripe</a>.
          Stripe enable us to securely provide and sell products
          and/or services online. Stripe is fully compliant with
          the General Data Protection Regulation (GDPR). For more
          information visit
          <a
          href="https://stripe.com/cookies-policy/legal"
          target="_blank">Stripe Cookies Policy</a></p>
        <h5>Microsoft Clarity</h5>
        <p>Another third party service that we use is
          <a href="https://clarity.microsoft.com/" target="_blank">Microsoft Clarity</a>.
          Microsoft Clarity enable us to better understand which content has more success
          as well as, how a user interacts with our page and to find possibly confusing
          page sections or articles. Which leads us to better understand how to improve
          our content. Also, Microsoft Clarity is fully compliant with the GDPR.
          For more information you can visit the
          <a href="https://privacy.microsoft.com/en-gb/privacystatement"
            target="_blank">Microsoft Privacy Policy</a>.</p>
        <h5>Youtube</h5>
        <p><a href="https://www.youtube.com" target="_blank">Youtube</a> is another
          third party service that we use to be able to display
          video content in our website. Youtube, is fully compliant with the GDPR.
          For more information you can visit the
          <a href="https://policies.google.com/privacy"
            target="_blank">Youtube Privacy Policy (Google Services Policy)</a>.</p>
        <h5>Google Analytics</h5>
        <p><a href="https://analytics.google.com/analytics/web/" target="_blank">
          Google Analytics</a>
          is a third party service that we use to understand how a user
          interacts with our page. This enables us to further improve our content.
          Nevertheless, Google is fully compliant with the GDPR.
          For more information you can visit the
          <a href="https://policies.google.com/privacy"
            target="_blank">Google Privacy Policy</a>.</p>
        <h5>Facebook</h5>
        <p>We also use <a href="https://www.facebook.com" target="_blank">Facebook</a>
          third party service to be able to show some of our clients reviews
          as well as to improve the efficiency of our Facebooks ads.
          For more information you can visit the
          <a href="https://www.facebook.com/privacy/explanation"
            target="_blank">Facebook Privacy Policy</a>.</p>
      </section>
      <section id="disable-cookies" class="row 200%">
        <h3>Disable Cookies</h3>
        <p>To enable, disable or delete cookies installed on your device you need
          to properly configurate your browser.
        </p>
        <p>For more information, open the link that matches your browser:</p>
        <ul>
          <li><a
            href="https://support.mozilla.org/en-US/kb/block-websites-storing-cookies-site-data-firefox?redirectslug=Blocking+cookies&redirectlocale=en-US"
            target="_blank"
            >Firefox</a></li>
          <li><a
            href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en-GB"
            target="_blank"
            >Chrome</a></li>
          <li><a
            href="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
            target="_blank">
            Internet Explorer</a></li>
          <li><a
            href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
            target="_blank">
            Microsoft Edge</a></li>
          <li><a
            href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac"
            target="_blank">Safari on Mac</a> or
            <a
            href="https://support.apple.com/en-us/HT201265"
            target="_blank">Safari on iPhone, iPad or iPod touch</a></li>
          <li><a
            href="https://support.brave.com/hc/en-us/articles/360050634931-How-Do-I-Manage-Cookies-In-Brave-"
            target="_blank">Brave</a></li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
import BannerImageOverlay from '../../components/common/utils/BannerImageOverlay.vue';

export default {
  name: 'CookiesPolicy',
  components: {
    BannerImageOverlay,
  },
};
</script>

<style scoped>
  .container {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .container section:last-child > :last-child {
    margin-bottom: 0;
  }

  section.row > ul {
    padding-left: 5em;
  }

  section.row > h3 {
      padding-left: 3em;
  }

  section.row > h4 {
      padding-left: 3.6em;
  }

  section.row > h5 {
      padding-left: 4.5em;
  }

  section > h3,
  section > h4,
  section > h5,
  section.row > ul {
    width: 100%;
  }

  @media screen and (max-width: 1280px) {
    section.row > h3 {
      padding-left: 2.2em;
    }

    section.row > h4 {
      padding-left: 2.65em;
    }

    section.row > h5 {
      padding-left: 3.3em;
    }
  }

  @media screen and (max-width: 736px) {
    section.row > h3 {
      padding-left: 1.88em;
    }

    section.row > h4 {
      padding-left: 2.25em;
    }

    section.row > h5 {
      padding-left: 2.75em;
    }
  }
</style>
